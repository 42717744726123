<template>
  <div class="about">
    <div class="about_inner">
      <div class="desc">
        <div class="abs_background abs_background_1">
          <div class="abs_inner" :style="{ backgroundImage: 'url(' + require('@/assets/images/ui/about_1.jpg') + ')' }"></div>
          <div class="bg_video_reveal bg_video_reveal_left"></div>
          <div class="bg_video_reveal bg_video_reveal_right"></div>
        </div>
        <div class="column_all">
          <div class="adn font_anton" v-html="adn">
          </div>
        </div>
      </div>
      <div class="what">
        <div class="what_inner">
          <div class="title font_bebas">crazymage</div>
          <div class="text font_oswald">
            <div v-html="block_1"></div>
            <div v-html="block_2"></div>
          </div>
        </div>
      </div>
      <div class="teams">
        <div class="teams_inner">
          <div class="team">
            <img :src="require('@/assets/images/ui/raphael.jpg')" />
            <div class="prenom font_anton">Raphaël</div>
            <div class="metier font_oswald">Producteur</div>
            <div class="person_contact font_oswald"><a :href="'mailto:' + 'raphael@crazymage.fr'">raphael@crazymage.fr</a></div>
          </div>
          <div class="team">
            <img :src="require('@/assets/images/ui/clem.jpg')" />
            <div class="prenom font_anton">Clémence</div>
            <div class="metier font_oswald">Directrice Financière</div>
            <div class="person_contact font_oswald"><a :href="'mailto:' + 'c.devisme@crazymage.fr'">c.devisme@crazymage.fr</a></div>
          </div>
          <div class="team">
            <img :src="require('@/assets/images/ui/axel.jpg')" />
            <div class="prenom font_anton">Axel</div>
            <div class="metier font_oswald">Directeur Artistique</div>
            <div class="person_contact font_oswald"><a :href="'mailto:' + 'axel@crazymage.fr'">axel@crazymage.fr</a></div>
          </div>
          <div class="team">
            <img :src="require('@/assets/images/ui/fanny.jpg')" />
            <div class="prenom font_anton">Fanny</div>
            <div class="metier font_oswald">Productrice Exécutive</div>
            <div class="person_contact font_oswald"><a :href="'mailto:' + 'fanny@crazymage.fr'">fanny@crazymage.fr</a></div>
          </div>
        </div>
      </div>
      <div class="contact">
        <div class="abs_background abs_background_2">
          <div class="abs_inner" :style="{ backgroundImage: 'url(' + require('@/assets/images/ui/about_2.jpg') + ')' }"></div>
        </div>
        <div class="contact_inner">
          <div class="font_anton mail"><a :href="'mailto:' + 'hello@crazymage.fr'">hello@crazymage.fr</a></div>
        </div>
      </div>
      <div class="clients">
        <div class="title font_oswald">clients</div>
        <div class="logos_outer">
          <div class="logos">
            <a
              v-for="(client, index) in clients"
              :key="index"
              class="logo_client"
            ><img :src="cdn + '/images/logos/' + client.logo" /></a>
          </div>
        </div>
      </div>
      <div class="adresses">
        <div class="adresse" :style="{ backgroundImage: 'url(' + require('@/assets/images/ui/paris.jpg') + ')' }">
          <div>
            <p class="font_oswald">
              <span class="ville">Paris</span><br/>Studio Taiko<br/>6bis Cité d'Angoulême<br/>75011 Paris
            </p>
          </div>
        </div>
        <div class="adresse" :style="{ backgroundImage: 'url(' + require('@/assets/images/ui/bordeaux.jpg') + ')' }">
          <div>
            <p class="font_oswald">
              <span class="ville">Bordeaux</span><br/>Siège Social<br/>11 rue Cornac<br/>33000 Bordeaux
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { gsap, Power4 } from 'gsap'
import _ from 'lodash'

export default {
  name: 'About',
  metaInfo () {
    return {
      title: this.$store.state.meta.basic + ' | About | We make stories',
      meta: [
        { name: 'robots', content: this.$store.state.meta.robots },
        { name: 'description', content: this.$store.state.meta.description },
        { property: 'og:title', content: this.$store.state.meta.og_title + ' | About | We make stories' },
        { property: 'og:site_name', content: this.$store.state.meta.og_site_name },
        { property: 'og:type', content: this.$store.state.meta.og_type },
        { property: 'og:url', content: this.$store.state.meta.og_url + 'about' },
        { property: 'og:image:type', content: this.$store.state.meta.og_image_type },
        { property: 'og:image', content: this.$store.state.meta.og_image }
      ]
    }
  },
  components: {
  },
  data () {
    return {
      cdn: this.$store.state.cdn
    }
  },
  beforeRouteLeave (to, from, next) {
    this.$store.state.loading_open = true
    this.$store.state.loading_leave = true
    // LOADING TITLE
    if (to.name === 'home') this.$store.state.loading_title = 'crazymage'
    else if (to.name === 'projet') this.$store.state.loading_title = 'work'
    else this.$store.state.loading_title = to.name
    setTimeout(function () {
      next()
    }, this.$store.state.loading_time_before)
  },
  beforeRouteEnter (to, from, next) {
    window.scrollTo(0, 0)
    next(vm => {
      // vm.$store.state.loading_time_after = 1150
      vm.$store.state.loading_time_after = 0
    })
  },
  mounted: function () {
    const self = this
    if (!this.$store.state.loading_first) {
      setTimeout(function () {
        self.$store.state.loading_open = false
      }, this.$store.state.loading_time_after)
    } else {
      this.$store.state.loading_open = false
    }
    this.bg_scroll()
    const timeStartAnim = this.$store.state.loading_first ? 2500 : 0
    setTimeout(function () {
      self.loadFirstImage()
    }, timeStartAnim)
  },
  computed: {
    clients () {
      return _.orderBy(this.$store.state.clients, function (e) { return parseInt(e.ordre) }, ['asc'])
    },
    adn () {
      const aboutAdn = _.find(this.$store.state.about, function (e) { return e.type === 'adn' })
      return aboutAdn.text
    },
    block_1 () {
      const aboutAdn = _.find(this.$store.state.about, function (e) { return e.type === 'block_1' })
      return aboutAdn.text
    },
    block_2 () {
      const aboutAdn = _.find(this.$store.state.about, function (e) { return e.type === 'block_2' })
      return aboutAdn.text
    }
  },
  methods: {
    loadFirstImage () {
      const monImage = new Image()
      monImage.addEventListener('load', function () {
        gsap.timeline()
          .to('.bg_video_reveal_left', { width: 0, duration: 1, ease: Power4.easeInOut }, 0)
          .to('.bg_video_reveal_right', { width: 0, duration: 1, ease: Power4.easeInOut }, 0)
          .to('.adn', { opacity: 1, duration: 1 }, 0.5)
      })
      monImage.src = require('@/assets/images/ui/about_1.jpg')
    },
    bg_scroll () {
      gsap.timeline({
        scrollTrigger: {
          trigger: '.desc',
          start: '-60px top',
          end: 'bottom',
          markers: false,
          scrub: true,
          pin: false
        }
      })
        .to('.abs_background_1', { y: innerHeight / 2 * 0.5 }, 0)
      gsap.timeline({
        scrollTrigger: {
          trigger: '.contact',
          start: 'top bottom',
          end: 'bottom top',
          markers: false,
          scrub: true,
          pin: false
        }
      })
        .to('.abs_background_2', { y: innerHeight / 2 * 0.14 }, 0)
    }
  }
}
</script>

<style lang="scss" scoped>
  .about_inner {
    min-height: 100vh;
    // padding: 40px 25px;
    .abs_background {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      .abs_inner {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          background: #000;
          z-index: 0;
          opacity: 0.4;
        }
      }
      &.abs_background_1 {.abs_inner {width: 105%; height: 105%;}}
      &.abs_background_2 {.abs_inner {width: 120%; height: 120%;}}
      .bg_video_reveal {
        position: absolute;
        top: 0;
        width: 50%;
        height: 100%;
        z-index: 2;
        background: #141414;
        // background: red;
        &.bg_video_reveal_left {
          // background: red;
          left: 0;
        }
        &.bg_video_reveal_right {
          // background: green;
          right: 0;
        }
      }
    }
    .desc {
      background: #141414;
      padding: 0px 0px;
      padding-top: 80px;
      display: flex;
      position: relative;
      overflow: hidden;
      background-size: cover;
      background-position: center;
      // background-attachment: fixed;
      .column_all {
        z-index: 1;
        display: flex;
        justify-content: center;
        width: 100%;
        .adn {
          letter-spacing: 0.1em;
          opacity: 0;
          font-size: 1.3em;
          padding: 100px 30px;
          text-align: left;
          max-width: 950px;
        }
      }
    }
    .contact {
      position: relative;
      overflow: hidden;
      background-size: cover;
      background-position: center;
      //background-attachment: fixed;
      padding: 150px 30px;
      &::after {
        //content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: #000;
        z-index: 0;
        opacity: 0.4;
      }
      .contact_inner {
        position: relative;
        z-index: 1;
        color: #fff;
        .mail {
          color:#141414;
          display: flex;
          justify-content: center;
          a {
            cursor: pointer;
            color: #fff;
            text-decoration: none;
            font-size: 2em;
            letter-spacing: 0.03em;
            padding: 10px 20px;
            border-radius: 10px;
          }
        }
      }
    }
    .what {
      display: flex;
      justify-content: center;
      padding: 40px 0px;
      .what_inner {
        display: flex;
        flex-direction: column;
        max-width: 950px;
      }
      .title {
        font-size: 4.0em;
        letter-spacing: 0.05em;
        text-align: center;
      }
      .text {
        padding-top: 20px;
        padding-left: 25px;
        padding-right: 25px;
        font-size: 1.1em;
        letter-spacing: 0.05;
        line-height: 1.8em;
        display: flex;
        flex-direction: column;
        div {
          width: 100%;
          padding: 0 0px;
          &:nth-child(2) {
            padding-top: 15px;
          }
        }
      }
    }
    .teams {
      background: #141414;
      padding: 20px 25px 60px 25px;
      .teams_inner {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        .team {
          width: 50%;
          &:nth-child(3), &:nth-child(4) {
            padding-top: 30px;
          }
          img { width: 100%; padding-bottom: 15px; }
          .prenom { color: #FFF; font-size: 1.2em; letter-spacing: 0.05em; font-weight: 300; line-height: 1em;
            a {
              color: #fff;
              text-decoration: none;
              span {
                font-size: 0.8em;
                color: #aaa;
              }
            }
          }
          .metier { color: #FFF; letter-spacing: 0.05em; }
          .person_contact {
            color: #FFF; letter-spacing: 0.05em;
            a {
              span {padding-right: 4px;}
              color: #ccc;
              text-decoration: none;
            }
          }
        }
      }
    }
    .clients {
      background: #141414;
      padding: 40px 25px;
      color: #fff;
      display: flex;
      flex-direction: column;
      align-items: center;
      .title {
        font-size: 3em;
        letter-spacing: 0.05em;
        text-align: center;
        text-transform: uppercase;
        padding-bottom: 20px;
      }
      .logos_outer {}
      .logos {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        max-width: 900px;
        .logo_client {
          // padding: 0 20px;
          width: 33.33%;
          display: flex;
          justify-content: center;
          img { width: 80%; max-width: 150px; }
        }
      }
    }
    .adresses {
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-content: center;
      .adresse {
        display: flex;
        justify-content: center;
        width: 50%;
        background-size: cover;
        background-position: center;
        position: relative;
        z-index: 1;
        padding-top: 150px;
        padding-bottom: 150px;
        p {
          display: inline;
          padding: 0.2em 0em;
          background-color: #141414;
          box-shadow: 0.50em 0 0 #141414,-0.50em 0 0 #141414;
          box-decoration-break: clone;
          line-height: 2;
          margin: 0px;
          margin-left: 0.5em;
        }
        &::after {
          //content: '';
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          background: #000;
          z-index: 0;
          opacity: 0.4;
        }
        .ville {
          // letter-spacing: 0.2em;
          //font-size: 1.5em;
          //display: inline-block;
          //letter-spacing: 0.05em;
          //padding: 0em 0em;
        }
      }
    }

    @media screen and (min-width: 42em) {
      .desc {
        .column_all {
          .adn {
            letter-spacing: 0.1em;
            font-size: 1.6em;
            padding: 150px 30px;
          }
        }
      }
      .what {
        .what_inner {
          .title { font-size: 6em; }
          .text { flex-direction: row; div { width: 50%; padding: 0 20px; &:nth-child(2) {padding-top: 0px;} } }
        }
      }
      .teams {
        .teams_inner {
          flex-wrap: nowrap;
          .team {
            &:nth-child(3), &:nth-child(4) {padding-top:0;}
            width: auto;
            img { width: 100%; max-width: 250px;}
            .prenom { font-size: 1.2em; }
            .metier {  }
          }
        }
      }
      .clients {
        .title {font-size: 5em;}
        .logos {.logo_client{width:25%;img{width: 80%;}}}
      }
      .contact { .contact_inner { .mail { a { font-size: 3em; } } } }
    }
    @media screen and (min-width: 60em) {
      .desc {
        .column_all {
          .adn {
            letter-spacing: 0.1em;
            font-size: 1.8em;
            padding: 180px 30px;
          }
        }
      }
      .clients {
        .title {font-size: 5em;}
        .logos {.logo_client{width:20%;img{width: 80%;}}}
      }
      .teams {
        .teams_inner {
          .team {
            .prenom { font-size: 1.5em; }
            .metier {  }
          }
        }
      }
      .contact { .contact_inner { .mail { a { font-size: 4em; } } } }
    }
    @media screen and (min-width: 72em) {
      .desc {
        .column_all {
          .adn {
            letter-spacing: 0.1em;
            font-size: 2em;
            padding: 200px 30px;
          }
        }
      }
    }
    @media screen and (min-width: 110em) {

    }

  }
</style>
